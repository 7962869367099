import React from 'react'
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"
import Layout from "../components/Layout"
import Modal from '../components/Modal'
import Complete from '../components/AthleteSignup/Complete'
import modal_background from "../images/modal_bg_texture.jpg"
import { X } from '../components/Icons'

const AthleteCompleteSignUp = ({ data }) => {
    const completeContent = data.formsYaml.pages[data.formsYaml.pages.length - 1];
    return (
        <Layout>
            <div css={tw`h-screen w-screen`}>
            <Modal
                open
                modalCss={[
                tw`bg-blend-multiply p-4 w-full lg:max-w-7xl`,
              `background: linear-gradient(146deg, #9E1B32 0%, #9E1B32 50%, #FFF 50.1%, #FFF 100%), url(${modal_background});`,
            ]}
            >
                <div
                    css={[
                    tw`grid grid-cols-1 sm:grid-cols-8 h-full`,
                    "max-height: 100vh",
                ]}
                >
                    <div
                        css={[
                        tw`col-span-5 overflow-y-auto bg-gray-mid text-white`,
                        "max-height: 90vh",
                        ]}
                    >
                        <Complete title={completeContent.title} paragraph={completeContent.paragraph} active />
                    </div>
                    <Link
                        to="/"
                        aria-label="Home"
                        css={[tw`absolute top-8 right-8 leading-none z-20`]}
                    >
                        <X css={[tw`text-white h-6 w-6`]} />
                    </Link>
                    <div css={[tw`hidden sm:block col-span-3 relative text-white`]}>
                        <GatsbyImage
                        image={getImage(data.file)}
                        alt="Bryant Denny Stadium"
                        css={[tw`top-0 right-0 w-full h-full object-cover absolute`]}
                        />
                        <div
                            css={[
                                tw`absolute top-0 left-0 w-full z-10 flex flex-col text-center items-center justify-center h-full p-8`,
                            ]}
                        >
                            <h2 css={tw`mb-16`}>
                                Sign up as a{" "}
                                <span className="stroke-text white" css={[tw`block`]}>
                                student athlete
                                </span>
                            </h2>

                            <div css={[tw`relative mb-4`]}>
                                <span
                                css={[
                                    tw`block absolute w-full text-center text-8xl top-1/2 transform -translate-y-1/2 opacity-50 font-black`,
                                ]}
                                >
                                {data.formsYaml.pages.length}
                                </span>
                                <h3 css={[tw`text-2xl`]}>
                                {completeContent["title"]}
                                </h3>
                            </div>
                        </div>
                    </div>    
                </div>
            </Modal>
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        formsYaml(form: {eq: "athlete"}) {
            pages {
              cta_button_text
              paragraph
              title
              component
              cta_button_link
            }
        }
        file(relativePath: { eq: "athlete_modal_image.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
        }
    }
`

export default AthleteCompleteSignUp